<template>
  <div class="apply-page">

    <div class="columns is-mobile apply-container has-background-white is-centered">
      <div class="column is-6 is-hidden-mobile apply-splash">
        <div class="columns is-centered">
          <div class="column is-8">
            <img alt class="apply-logo" src="@/assets/cornerstone-logo.png"/>
          </div>
        </div>
        <div class="columns is-hidden-mobile is-centered">
          <div
            class="column is-8 instructions-text-container  has-text-black px-5 py-5"

          >
            <p class="is-size-4">Returning Students</p>
            <p class="">If you're a returning student or have submitted an application in the
              past & interacted with the student portal, but didn't complete the process, please login
              <router-link to="/login?returning=1">here</router-link>
            </p>
            <p class="">
              When answering ‘Have you completed a previous application?’</p>
            <ul class="ml-3 pb-4 "
                style="list-style-type: circle; list-style-position: outside">
              <li>
                Select 'no', if you’ve never logged into the student portal and 'yes' if you've accessed the student
                portal before
              </li>
              <li>
                If you have forgotten your password, or are not sure of your login details, please click 'forgot'
                password and enter the email address you used and a password reset request will be sent to the email you
                used
                when you made contact with the institution
              </li>
            </ul>


            <p class="is-size-4">Steps on how to submit your application</p>

            <ul class="ml-3 pb-4" style="list-style-type: circle; list-style-position: outside">
              <li>Submit your basic information and select your programme of choice by completing the form on the right
              </li>
              <li>Please make sure you have selected the correct programme, year and semester of choice
              </li>
              <li>You will be required to create a password. Be sure to save it as you’ll use it to login to access your
                profile in the future
              </li>
              <li>Once logged in complete all of the required information and upload the supporting documents
              </li>
              <li>Once all of the fields have been completed, the Cornerstone team will review your application</li>
              <li>Pay your application fee and attach a proof of payment to the application within the portal
              </li>
              <li>In order to speed up the application process, ensure that all of the required fields have been
                accurately completed and that all required documents have been uploaded
              </li>
            </ul>

            <p class="is-size-5">
              Documents required:
            </p>
            <ul class="ml-3 pb-1" style="list-style-type: circle; list-style-position: outside">
              <li>Certified copy of National Senior Certificate or Equivalent</li>
              <li>Certified copies of tertiary qualifications including academic record(s)</li>
              <li>Certified copy of RSA ID /OR Certified copy of Passport for non-South African citizens</li>
              <li>If you have a foreign qualification, it must have the appropriate SAQA Verification Certificate</li>
              <li>Proof of payment</li>
            </ul>

            <p class="pb-2">Please note, if the document does not apply to you, you can submit your supporting documents
              without it. To download these steps and checklist, click <a download
                                                                          href="https://cornerstone-aas-assets.s3.eu-west-1.amazonaws.com/downloads/Downloadable+Instructions.pdf"
                                                                          target="_blank">here</a></p>
            <p> If you have any questions or require assistance with the process, please contact us at
              <a href="mailto:helpdesk@cornerstone.ac.za">helpdesk@cornerstone.ac.za</a> with your query.
            </p>
          </div>
        </div>
      </div>
      <div class="column is-fullheight is-6-desktop is-10-mobile">
        <div class="margin-top-double columns is-centered">
          <div class="column is-hidden-tablet is-4">
            <img alt class="apply-logo" src="@/assets/cornerstone-logo-dark.png"/>
          </div>
        </div>
        <div class="columns is-hidden-tablet is-centered">
          <div
            class="column is-8 instructions-text-container  has-text-black px-5 py-5"

          >
            <p class="is-size-4">Returning Students</p>
            <p class="">If you're a returning student or have submitted an application in the
              past & interacted with the student portal, but didn't complete the process, please login
              <router-link to="/login?returning=1">here</router-link>
            </p>
            <p class="">
              When answering ‘Have you completed a previous application?’</p>
            <ul class="ml-3 pb-4 "
                style="list-style-type: circle; list-style-position: outside">
              <li>
                Select 'no', if you’ve never logged into the student portal and 'yes' if you've accessed the student
                portal before
              </li>
              <li>
                If you have forgotten your password, or are not sure of your login details, please click 'forgot'
                password and enter the email address you used and a password reset request will be sent to the email you
                used
                when you made contact with the institution
              </li>
            </ul>


            <p class="is-size-4">Steps on how to submit your application</p>

            <ul class="ml-3 pb-4" style="list-style-type: circle; list-style-position: outside">
              <li>Submit your basic information and select your programme of choice by completing the form on the right
              </li>
              <li>Please make sure you have selected the correct programme, year and semester of choice
              </li>
              <li>You will be required to create a password. Be sure to save it as you’ll use it to login to access your
                profile in the future
              </li>
              <li>Once logged in complete all of the required information and upload the supporting documents
              </li>
              <li>Once all of the fields have been completed, the Cornerstone team will review your application</li>
              <li>Pay your application fee and attach a proof of payment to the application within the portal
              </li>
              <li>In order to speed up the application process, ensure that all of the required fields have been
                accurately completed and that all required documents have been uploaded
              </li>
            </ul>

            <p class="is-size-5">
              Documents required:
            </p>
            <ul class="ml-3 pb-1" style="list-style-type: circle; list-style-position: outside">
              <li>Certified copy of National Senior Certificate or Equivalent</li>
              <li>Certified copies of tertiary qualifications including academic record(s)</li>
              <li>Certified copy of RSA ID /OR Certified copy of Passport for non-South African citizens</li>
              <li>If you have a foreign qualification, it must have the appropriate SAQA Verification Certificate</li>
              <li>Proof of payment</li>
            </ul>

            <p class="pb-2">Please note, if the document does not apply to you, you can submit your supporting documents
              without it. To download these steps and checklist, click <a download
                                                                          href="https://cornerstone-aas-assets.s3.eu-west-1.amazonaws.com/downloads/Downloadable+Instructions.pdf"
                                                                          target="_blank">here</a></p>
            <p> If you have any questions or require assistance with the process, please contact us at
              <a href="mailto:helpdesk@cornerstone.ac.za">helpdesk@cornerstone.ac.za</a> with your query.
            </p>
          </div>

        </div>
        <div class="columns is-centered">
          <div class="column is-8">
            <h2 v-if="applicationsClosed" class="is-size-3 mb-6 has-text-weight-semibold">Applications are currently closed.</h2>
            <h2 v-else class="is-size-3 mb-6 has-text-weight-semibold">Apply Today!</h2>

            <ValidationObserver ref="observer" v-slot="{ handleSubmit,invalid  }">

              <form class="apply-form pb-4" @submit.prevent="handleSubmit(submit)">

                <b-field label="First Name">
                  <b-input
                    v-model="user.first_name"
                    :disabled="applicationsClosed"
                    autofocus
                    name="first name"
                    placeholder="Bob"
                    required
                  />
                </b-field>
                <b-field label="Middle Name(s)">
                  <b-input
                    v-model="user.middle_name"
                    :disabled="applicationsClosed"
                    autofocus

                    name="middle name"
                    placeholder="Samuel"
                  />
                </b-field>
                <b-field label="Last Name">
                  <b-input
                    v-model="user.last_name"
                    :disabled="applicationsClosed"
                    autofocus
                    name="last name"
                    placeholder="Smith"
                    required
                  />
                </b-field>
                <b-field label="Identity type">
                  <b-select v-model="user.identification_type_id"

                            :disabled="applicationsClosed"
                            class="style-force"
                            icon="id-card"
                            placeholder="Identity type"
                            required
                  >
                    <option
                      v-for="identification_type in identification_types"
                      :key="identification_type.id"
                      :value="identification_type.id"
                    >{{ identification_type.name }}
                    </option>
                  </b-select>
                </b-field>

                <b-field label="Identification number">
                  <ValidationProvider v-slot="{ errors }"
                                      :rules="user.identification_type_id===9? 'required|sa_id':'required'">

                    <b-input
                      v-model="user.id_number"
                      :class="{'danger-border':errors.length>0}"
                      :disabled="applicationsClosed"

                      autocomplete="chrome-off"
                      placeholder="ID number"
                      required
                      @keypress.native.space.prevent
                    />
                    <span class="is-danger">{{ errors[0] }}</span>

                  </ValidationProvider>
                </b-field>
                <b-field label="E-mail Address">
                  <b-input
                    v-model="user.email"
                    :disabled="applicationsClosed"
                    autofocus
                    name="email"
                    placeholder="bobsmith@email.com"
                    required
                    type="email"
                  />
                </b-field>
                <b-field v-if="existingUser == true" label="Password">
                  <b-input
                    v-model="user.password"
                    :disabled="applicationsClosed"
                    :password-reveal="true"
                    name="password"
                    required
                    type="password"
                  />
                </b-field>
                <template v-if="newUser"><p>Please enter a password below. It must be a minimum of 8 characters. Be sure
                  to remember
                  this
                  password to access to your profile</p>
                  <b-field label="Password">
                    <ValidationProvider v-slot="{ errors }" rules="required|min|password:@confirm">
                      <b-input
                        v-model="user.password"
                        :class="{'danger-border':errors.length>0}"
                        :disabled="applicationsClosed"
                        :password-reveal="true"
                        name="password"
                        type="password"
                      />
                      <span class="is-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>

                  <b-field label="Confirm password">
                    <ValidationProvider v-slot="{ errors }" name="confirm" rules="required">
                      <b-input
                        v-model="user.password_confirmation"
                        :class="{'danger-border':errors.length>0}"
                        :disabled="applicationsClosed"
                        :loading="isLoading"
                        :password-reveal="true"
                        type="password"
                      />
                      <span class="is-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-field>
                </template>


                <hr/>
                <b-field>
                  <b-checkbox v-model="termsAndConditions" required>I have hereby read and agree to the <a
                    href="https://cornerstone.ac.za/about-us/privacy-policy-and-disclaimer/"
                    target="_blank">Terms
                    and Conditions.</a> If you are under 18 your Guardian/Parent needs to read and agree.
                  </b-checkbox>
                </b-field>
                <b-field>
                  <b-field grouped>
                    <div class="control">
                      <button
                        :class="{'is-loading':isLoading}"
                        :disabled="invalid"
                        class="button is-primary"
                        type="submit"
                      >Next Step
                      </button>
                    </div>
                    <div v-if="existingUser == true" class="control">
                      <router-link
                        class="button is-primary is-outlined"
                        target="_blank"
                        to="/password-recovery"
                      >Forgot password
                      </router-link>
                    </div>
                  </b-field>
                </b-field>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <b-modal :active.sync="isVideoModalActive" :content="videoEmbed" class="has-text-centered"></b-modal>
    </div>
  </div>
</template>

<script>
import {AuthClient} from "@/clients/AuthClient";
import VueCookie from "vue-cookie";
import {CLIENT_SECRET} from "@/config";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import Lookup from "@/models/Lookup";
import Country from "@/models/Country";
import State from "@/models/State";
import City from "@/models/City";
import set from "lodash/set";
import handleError from "@/mixins/ResponseErrors";
import validate_id from "@/mixins/SAIDNumberValidation"
import {isWithinInterval} from "date-fns";

extend("required", {
  ...required,
  message: "This field is required",
});
extend("password", {
  params: ["target"],
  validate(value, {target}) {
    return value === target;
  },
  message: "Password confirmation does not match",
});
extend("min", (value) => {
  if (value.length >= 8) {
    return true;
  }
  return "The password must be at least 8 characters";
});
extend("sa_id", (value) => {
  let check = validate_id.methods.validate_id(value)
  if (check.error === null) {
    return true;
  }
  return check.error;
});
export default {
  name: "Apply",
  components: {ValidationProvider, ValidationObserver},
  data() {
    return {
      videoEmbed: `
      <iframe
          width="560"
          height="315"
          class="youtube-embed"
          src="https://www.youtube.com/embed/09IrEdd0UF4?autoplay=1""
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>`,
      isVideoModalActive: false,
      isLoading: false,
      newUser: false,
      existingUser: false,
      termsAndConditions: false,
      user: {
        first_name: "",
        middle_name: "",
        last_name: "",
        id_number: null,
        email: "",
        identification_type_id: null,
        password: null,
        password_confirmation: null,
        is_learner: 1,
      },
    };
  },
  mounted() {
    this.$store.commit("fullPage", true);
    this.$store.commit("asideRightToggle", false);
    this.$store.commit("asideStateToggle", false);
  },
  created() {

    this.$store.commit("fullPage", true);
    this.$store.commit("asideRightToggle", false);
    this.$store.commit("asideStateToggle", false);
    Lookup.fetchIDTypes()
    Country.fetchAllPublic({page: 1, limit: 999})


  },
  beforeDestroy() {
    this.$store.commit("fullPage", false);
  },
  computed: {
    applicationsClosed() {
      return !isWithinInterval(new Date(), {
       start: new Date('September 13, 2024 00:00:00'),
       end: new Date('December 31, 2024 18:59:00')
      })
    },

    cities() {
      return City.query().orderBy('name').get()
    },
    countries() {
      return [Country.query().where('name', 'South Africa').first() ?? {}, ...Country.query().orderBy('name').get()]
    },
    identification_types() {
      return Lookup.query().where("lookup_type_id", 3).get();
    },

  },
  mixins: [handleError],

  watch: {
    'user.id_number'(newValue) {
      this.user.id_number = newValue.replace(/ /g, '');

    }
  },
  methods: {
    validateAutocomplete(source, target) {
      if (source == null) {
        set(this, target, null)
      }
    },
    getStateId(name, country_id) {

      return parseInt(State.query().where('country_id', country_id).where('name', name).first().id)
    },
    getCountryId(name) {
      return Country.query().where('name', name).first().id
    },
    getFilteredCity(text, state_id) {
      City.deleteAll()
      City.fetchAllPublic({page: 1, limit: 30}, {state_id: state_id, search: text})
    },
    selectedCountryStates(country_id) {
      if (country_id) {
        return State.query().where('country_id', country_id).orderBy('name').get()
      }
      return []
    },

    getStates(value) {
      this.$store.dispatch('loader/show')
      Country.fetchAllPublic({page: 1, limit: 5}, {name: value}, ['states']).then(() => {
        this.$store.dispatch('loader/hide')
      })
    },
    showVideoModal() {
      this.isVideoModalActive = true;
    },
    login(password) {
      AuthClient.login({
        is_existing_student: false,
        username: this.user.id_number,
        password: password,
        client_id: "2",
        client_secret: CLIENT_SECRET,
        grant_type: "password",
      })
        .then((result) => {
          VueCookie.set("access_token", result.data.access_token);

          this.$store.dispatch("loader/hide");

          window.location.href = "/registrations/mine";
        })
        .catch((res) => {
          let error_message = "";
          let error_status = res.response.status;

          if (error_status === 401) {
            error_message = "The credentials that you supplied are incorrect.";
          }
          if (error_status === 403) {
            error_message = "You are trying to access a forbidden area";
          }

          this.$store.dispatch("toast/createToast", {
            message: error_message,
          });

          this.isLoading = false;
        });
    },
    async submit() {

      this.isLoading = true;

      AuthClient.checkIfExists({id_number: this.user.id_number})
        .then(() => {
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.response.status === 302) {
            if (this.existingUser === false) {
              return this.$buefy.dialog.confirm({
                title: "Existing User",
                message:
                  "It looks like you already have an account. Please go to the login page to continue.",
                confirmText: "Go to login",
                canCancel: ["escape", "outside"],
                type: "is-success",
                hasIcon: true,
                onConfirm: () => {
                  (this.$router.push("/login"))
                }
              });
            }
            this.login(this.user.password);
          } else if (err.response.status === 404) {
            if (this.newUser === false) {
              return this.$buefy.dialog.confirm({
                title: "New User",
                message:
                  "It looks like you don't have an account yet. Please create a password to continue",
                confirmText: "Close",
                canCancel: ["escape", "outside"],
                type: "is-success",
                hasIcon: true,
                onConfirm: () => (this.newUser = true),
                onCancel: () => (this.newUser = true),
              });
            }

            this.$store.dispatch("loader/show");
            AuthClient.register(this.user)
              .then(() => {
                this.login(this.user.password);
              })
              .catch((err) => {
                this.handleError(err)
                this.$store.dispatch("loader/hide");
              });
          }
        });
    },
  },
};
</script>
